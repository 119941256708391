<template>
  <div>
    <div class="box">
      <h1>Web通用渠道追踪测试</h1>
      <button @click="but">点击事件</button>
    </div>
  </div>
</template>

<script>
import "../../../public/sdk/sensorsdata";
export default {
  methods: {
    but(e) {
      var tutor = window["tutorDataAnalytic201505"];
      tutor.init({
        server_url: "https://demo.tutordata.cn/sa?project=ecommerce",
        use_client_time: true,
        send_type: "ajax",
        heatmap: {},
      });

      tutor.quick("autoTrack");
      // 初始化深度链接插件
      tutor.quick("useAppPlugin", {
        deeplink: function (deeplink) {
          deeplink.init({ timeout: 2900 }); // 等待唤起 App 的时间，默认 3000 ms

          //   document
          //     .getElementById("aclick")
          //     .addEventListener("click", function (e) {
          e.preventDefault();
          deeplink.openDeepLink();
          // });
        },
      });
    },
  },
};
</script>

<style scoped >
.box {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
h1 {
  margin: 50px;
}
button {
  width: 112px;
  height: 45px;
  font-size: 18px;
}
</style>
